<template>
  <form @submit.prevent="saveConfig">
    <modal-card :title="`Configure database backups`" :processing="processing">
      <loading v-if="!site" />

      <section v-else>
        <p>
          Please enter your credentials so we can back up your database. If
          you're not sure where to find this information you can ask us for
          assistance.
        </p>

        <hr />

        <b-message type="is-warning">
          <p>
            Please allow remote connections to your database from the following
            IP address:
            <strong>192.250.234.9</strong>.
          </p>
        </b-message>

        <div>
          <b-field label="Username *">
            <b-input v-model="form.username" :placeholder="`Your username`" />
          </b-field>

          <b-field :label="`${newConfig ? 'Password *' : 'Password'}`">
            <b-input
              v-model="form.password"
              :placeholder="`${newConfig ? 'Your' : 'New'} password`"
              type="password"
              password-reveal
            />
          </b-field>

          <b-field label="Database name *">
            <b-input
              v-model="form.name"
              :placeholder="`eg. db_${site.fqdn.replace(/\./, '')}`"
            />
          </b-field>

          <b-field grouped group-multiline>
            <b-field label="Hostname *" expanded>
              <b-input v-model="form.host" :placeholder="`eg. ${site.fqdn}`" />
            </b-field>

            <b-field label="Port *">
              <b-input
                v-model="form.port"
                :placeholder="`eg. 3306`"
                type="text"
                style="width: 6rem;"
              />
            </b-field>
          </b-field>

          <b-field label="Skip tables (Optional)">
            <b-taginput
              v-model="form.skiptables"
              ellipsis
              rounded
              type="is-dark"
              placeholder="Add a table"
            />
          </b-field>
        </div>
      </section>

      <button
        slot="footer"
        :class="{ 'is-loading': processing }"
        :disabled="formIsDisabled"
        type="submit"
        class="button is-success"
      >
        Save
      </button>
    </modal-card>
  </form>
</template>

<script>
export default {
  name: "DbBackupConfigModal",
  props: {
    siteId: {
      type: String,
      required: true
    },
    config: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      processing: false,
      form: {
        dbms: "mysql",
        name: "",
        username: "",
        password: "",
        host: "",
        port: "3306",
        skiptables: []
      }
    };
  },
  computed: {
    site() {
      return this.$store.getters["sites/site"](this.siteId);
    },
    validConfig() {
      return (
        this.form.name &&
        this.form.username &&
        (this.newConfig ? this.form.password : true) &&
        this.form.host &&
        this.form.port &&
        this.form.skiptables
      );
    },
    newConfig() {
      return !this.config.id;
    },
    formIsValid() {
      if (this.validConfig) return true;
      return false;
    },
    formIsDisabled() {
      return this.processing || !this.formIsValid;
    },
    formPayload() {
      let payload = {
        siteid: this.siteId
      };
      if (this.validConfig) {
        payload = this.$_.merge(
          payload,
          this.$_.pick(this.form, [
            "dbms",
            "name",
            "username",
            this.form.password ? "password" : null,
            "host",
            "port"
          ]),
          {
            skiptables: this.form.skiptables.join(","),
            port: this.form.port.toString()
          },
          {
            username: this.$_.trim(this.form.username),
            host: this.$_.trim(this.form.host)
          }
        );
      }
      return payload;
    }
  },
  created() {
    this.$set(
      this,
      "form",
      this.$_.merge(
        {},
        this.form,
        this.$_.pick(this.config, [
          "name",
          "username",
          "password",
          "host",
          "port"
        ]),
        {
          skiptables: this.config.skiptables
            ? this.config.skiptables.split(",")
            : []
        }
      )
    );
  },
  methods: {
    saveConfig() {
      if (!this.formIsDisabled) {
        const type = this.newConfig ? "add-db-config" : "update-db-config";
        this.processing = true;
        this.$store
          .dispatch("sites/backups/api", {
            type,
            siteId: this.siteId,
            payload: this.formPayload
          })
          .then(result => {
            this.$emit("refresh");
            this.$toast.open({
              message: result.message,
              position: "is-bottom",
              queue: false
            });
          })
          .catch(() => {
            this.processing = false;
            this.$toast.open({
              message: "There was a problem saving your configuration",
              position: "is-bottom",
              queue: false,
              type: "is-danger"
            });
          });
      }
    }
  }
};
</script>
